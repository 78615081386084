<div class="w-full">
  <div class="flex flex-col gap-y-4 w-full max-h-full">
    <div class="max-h-full grid grid-cols-1 gap-x-4 gap-y-4 md:grid-cols-6 w-full">
      <div [ngClass]="{ 'col-span-3': cols == 2, 'col-span-6': cols == 1 }">
        <form class="flex flex-col gap-y-4 bg-card rounded-xl md:col-span-2 p-8" [formGroup]="form">
          <div class="col-span-1">
            <h2 class="text-lg font-semibold leading-7 text-gray-900">
              {{ "principal_information" | transloco }}
            </h2>
            <p class="mt-1 text-sm leading-6 text-gray-600">
              {{ "principal_information_desc" | transloco }}
            </p>
          </div>

          <div class="col-span-1">
            <div class="grid grid-cols-1 gap-x-6 gap-y-1 sm:grid-cols-6">
              <div class="sm:col-span-3" *ngIf="loginEdit">
                <mat-form-field class="w-full">
                  <mat-label>{{ "id" | transloco }}</mat-label>
                  <input matInput formControlName="login" [mask]="loginMask" class="uppercase" />
                  <mat-error *ngIf="form.get('login')?.hasError('required')">
                    {{ "id_required" | transloco }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="sm:col-span-3">
                <mat-form-field class="w-full">
                  <mat-label>{{ "name" | transloco }}</mat-label>
                  <input matInput formControlName="name" />
                  <mat-error *ngIf="form.get('name')?.hasError('required')">
                    {{ "name_required" | transloco }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="sm:col-span-3">
                <mat-form-field class="w-full">
                  <mat-label>{{ "email" | transloco }}</mat-label>
                  <input matInput formControlName="email" />
                  <mat-error *ngIf="form.get('email')?.hasError('required')">
                    {{ "email_required" | transloco }}
                  </mat-error>
                  <mat-error *ngIf="
                      !form.get('email')?.hasError('required') &&
                      form.get('email')?.hasError('email')
                    ">
                    {{ "email_invalid" | transloco }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="sm:col-span-3">
                <mat-form-field class="w-full">
                  <mat-label>{{ "nationality" | transloco }}</mat-label>
                  <mat-select [value]="1" formControlName="nationalityId">
                    <ng-container *ngFor="let country of countries">
                      <mat-option [value]="country.id">
                        <mat-icon class="icon-size-4" [svgIcon]="
                            'flags:' + country?.countryAlpha2Code?.toLowerCase()
                          "></mat-icon>
                        {{ country.nationality }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error *ngIf="form.get('nationalityId')?.hasError('required')">
                    {{ "nationality_required" | transloco }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="sm:col-span-3">
                <mat-form-field class="w-full">
                  <mat-label>{{ "document_type" | transloco }}</mat-label>
                  <mat-select formControlName="documentType">
                    <mat-option *ngFor="let docType of documentTypes" [value]="docType.value">
                      {{ docType.key | transloco }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.get('documentType')?.hasError('required')">
                    {{ "document_type_required" | transloco }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="sm:col-span-3">
                <div class="sm:col-span-6">
                  <mat-form-field class="w-full">
                    <mat-label>{{ "vat" | transloco }}</mat-label>
                    <input matInput formControlName="vat" [mask]="getVatNumberMask()" />
                    <mat-error *ngIf="form.get('vat')?.hasError('required')">
                      {{ "vat_required" | transloco }}
                    </mat-error>
                    <mat-error *ngIf="
                        !form.get('vat')?.hasError('required') &&
                        (form.get('vat')?.hasError('invalidCPF') ||
                          form.get('vat')?.hasError('pattern') ||
                          form.get('vat')?.hasError('invalidSsn') ||
                          form.get('vat')?.hasError('invalidCnpj'))
                      ">
                      {{ "invalid-document" | transloco }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="sm:col-span-3">
                <mat-form-field class="w-full">
                  <mat-label>{{ "gender" | transloco }}</mat-label>
                  <mat-select [value]="1" formControlName="gender">
                    <mat-option [value]="gender.Masculine">{{
                      "male" | transloco
                      }}</mat-option>
                    <mat-option [value]="gender.Feminine">{{
                      "female" | transloco
                      }}</mat-option>
                    <mat-option [value]="gender.Unknown">{{
                      "other" | transloco
                      }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.get('gender')?.hasError('required')">
                    {{ "gender_required" | transloco }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="sm:col-span-3">
                <div class="sm:col-span-6">
                  <mat-form-field class="w-full">
                    <mat-label>{{ "timezone" | transloco }}</mat-label>
                    <mat-select [value]="1" formControlName="timezone">
                      <ng-container *ngFor="let timezone of timezoneList">
                        <mat-option [value]="timezone.id">
                          {{ timezone.name }}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                    <mat-error *ngIf="form.get('timezone')?.hasError('required')">
                      {{ "timezone_required" | transloco }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="sm:col-span-3" *ngIf="showDepartment">
                <div class="sm:col-span-6">
                  <mat-form-field class="w-full">
                    <mat-label>{{ "access_group" | transloco }}</mat-label>
                    <mat-select formControlName="accessGroupIds" [multiple]="true">
                      <ng-container *ngFor="let group of accessGroups">
                        <mat-option [value]="group.id">
                          {{ group.name }}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                    <mat-error *ngIf="form.get('accessGroupIds')?.hasError('required')">
                      {{ "access_group_required" | transloco }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="sm:col-span-3" *ngIf="showDepartment">
                <mat-form-field class="w-full">
                  <mat-label>{{ "departments" | transloco }}</mat-label>
                  <mat-select formControlName="departmentIds" [multiple]="true">
                    <ng-container *ngFor="let department of departments">
                      <mat-option [value]="department.id">
                        {{ department.name }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error *ngIf="form.get('departmentIds')?.hasError('required')">
                    {{ "departments_required" | transloco }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="max-h-full grid grid-cols-1 gap-x-4 gap-y-4 md:grid-cols-6 w-full">
      <div [ngClass]="{ 'col-span-3': cols == 2, 'col-span-6': cols == 1 }">
        <form class="flex flex-col gap-y-4 bg-card rounded-xl md:col-span-2 p-8" [formGroup]="form">
          <div class="col-span-1">
            <h2 class="text-lg font-semibold leading-7 text-gray-900">
              {{ "contact_info" | transloco }}
            </h2>
            <p class="mt-1 text-sm leading-6 text-gray-600">
              {{ "contact_info_desc" | transloco }}
            </p>
          </div>

          <div class="col-span-1">
            <div class="grid grid-cols-1 gap-x-6 gap-y-1 sm:grid-cols-6">
              <div class="sm:col-span-2">
                <mat-form-field class="w-full">
                  <mat-label>{{ "birthday_date" | transloco }}</mat-label>
                  <input matInput [matDatepicker]="picker" formControlName="birthday"
                    [matDatepickerFilter]="reverseFilter" />
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error *ngIf="form.get('birthday')?.hasError('required')">
                    {{ "birthday_required" | transloco }}
                  </mat-error>
                  <mat-error *ngIf="
                      !form.get('birthday')?.hasError('required') &&
                      form.get('birthday')?.hasError('reverseFilter')
                    ">
                    {{ "invalid_birthday" | transloco }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="sm:col-span-2">
                <mat-form-field class="w-full">
                  <mat-label>{{ "primary_phone" | transloco }}</mat-label>
                  <input matInput formControlName="primaryPhoneNumber" [placeholder]="primaryPhoneNumberPlaceholder"
                    [mask]="primaryPhoneNumberMask" />
                  <mat-select matPrefix formControlName="primaryPhoneCountryCode"
                    (selectionChange)="onPrimaryCountryChange($event)">
                    <ng-container *ngFor="let country of countryOptions">
                      <mat-option [value]="'+' + country.value.dialCode">
                        <mat-icon class="icon-size-4"><img [src]="country.value.flag" /></mat-icon>
                        + {{ country.value.dialCode }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error *ngIf="form.get('primaryPhoneNumber')?.hasError('required')">
                    {{ "primary_phone_required" | transloco }}
                  </mat-error>
                  <mat-error *ngIf="
                      !form.get('primaryPhoneNumber')?.hasError('required') &&
                      form.get('primaryPhoneCountryCode')?.hasError('required')
                    ">
                    {{ "country_code_required" | transloco }}
                  </mat-error>
                  <mat-error *ngIf="
                      !form.get('primaryPhoneNumber')?.hasError('required') &&
                      !form
                        .get('primaryPhoneCountryCode')
                        ?.hasError('required') &&
                      form.get('primaryPhoneNumber')?.hasError('mask')
                    ">
                    {{ "country_code_mask" | transloco }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="sm:col-span-2">
                <mat-form-field class="w-full">
                  <mat-label>{{ "secondary_phone" | transloco }}</mat-label>
                  <input matInput formControlName="secondaryPhoneNumber" [placeholder]="secondaryPhoneNumberPlaceholder"
                    [mask]="secondaryPhoneNumberMask" />
                  <mat-select matPrefix formControlName="secondaryPhoneCountryCode"
                    (selectionChange)="onSecondaryCountryChange($event)">
                    <ng-container *ngFor="let country of countryOptions">
                      <mat-option [value]="'+' + country.value.dialCode">
                        <mat-icon class="icon-size-4"><img [src]="country.value.flag" /></mat-icon>
                        + {{ country.value.dialCode }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error *ngIf="
                      form.get('secondaryPhoneNumber')?.hasError('required')
                    ">
                    {{ "secondary_phone_required" | transloco }}
                  </mat-error>
                  <mat-error *ngIf="
                      !form.get('secondaryPhoneNumber')?.hasError('required') &&
                      form
                        .get('secondaryPhoneCountryCode')
                        ?.hasError('required')
                    ">
                    {{ "country_code_required" | transloco }}
                  </mat-error>
                  <mat-error *ngIf="
                      !form.get('secondaryPhoneNumber')?.hasError('required') &&
                      !form
                        .get('secondaryPhoneCountryCode')
                        ?.hasError('required') &&
                      form.get('secondaryPhoneNumber')?.hasError('mask')
                    ">
                    {{ "country_code_mask" | transloco }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="sm:col-span-2">
                <mat-form-field class="w-full">
                  <mat-label>{{ "country" | transloco }}</mat-label>
                  <mat-select formControlName="countryId">
                    <ng-container *ngFor="let country of countries">
                      <mat-option [value]="country.id">
                        <mat-icon class="icon-size-4" [svgIcon]="
                            'flags:' + country?.countryAlpha2Code?.toLowerCase()
                          "></mat-icon>
                        {{ country.name }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error *ngIf="form.get('countryId')?.hasError('required')">
                    {{ "country_required" | transloco }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="sm:col-span-1">
                <mat-form-field class="w-full">
                  <mat-label>{{ "zip_code" | transloco }}</mat-label>
                  <input matInput formControlName="postalCode" [mask]="getPostalCodeMask()" />
                  <mat-error *ngIf="form.get('postalCode')?.hasError('required')">
                    {{ "zip_code_required" | transloco }}
                  </mat-error>
                  <mat-error *ngIf="form.get('postalCode')?.hasError('mask')">
                    {{ "postal_code_mask" | transloco }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="sm:col-span-3">
                <mat-form-field class="w-full">
                  <mat-label>{{ "street" | transloco }}</mat-label>
                  <input matInput formControlName="street" />
                  <mat-error *ngIf="form.get('street')?.hasError('required')">
                    {{ "street_required" | transloco }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="sm:col-span-1">
                <mat-form-field class="w-full">
                  <mat-label>{{ "number" | transloco }}</mat-label>
                  <input matInput formControlName="number" type="number" />
                  <mat-error *ngIf="form.get('number')?.hasError('required')">
                    {{ "number_required" | transloco }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="sm:col-span-2">
                <mat-form-field class="w-full">
                  <mat-label>{{ "district" | transloco }}</mat-label>
                  <input matInput formControlName="district" />
                  <mat-error *ngIf="form.get('district')?.hasError('required')">
                    {{ "district_required" | transloco }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="sm:col-span-3">
                <mat-form-field class="w-full">
                  <mat-label>{{ "complement" | transloco }}</mat-label>
                  <input matInput formControlName="complement" />
                  <mat-error *ngIf="form.get('complement')?.hasError('required')">
                    {{ "complement_required" | transloco }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="sm:col-span-3">
                <mat-form-field class="w-full">
                  <mat-label>{{ "city" | transloco }}</mat-label>
                  <input matInput formControlName="city" />
                  <mat-error *ngIf="form.get('city')?.hasError('required')">
                    {{ "city_required" | transloco }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="sm:col-span-3">
                <mat-form-field class="w-full">
                  <mat-label>{{ "state" | transloco }}</mat-label>
                  <input matInput formControlName="state" />
                  <mat-error *ngIf="form.get('state')?.hasError('required')">
                    {{ "state_required" | transloco }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
